export function getLastURLSegment(): string {
  const router = useRouter();
  const path = router.currentRoute.value.path;
  const segments = path.split('/').filter((segment) => segment.trim() !== '');

  const languagePrefix = segments[0];
  if (languagePrefix && languagePrefix.length === 2) {
    segments.shift();
  }
  if (segments.length >= 2) {
    return `/${segments[segments.length - 2]}/${segments[segments.length - 1]}`;
  } else {
    return `/${segments[segments.length - 1]}`;
  }
}


export function urlFormat(url: string = ''): string {
  return url.toLowerCase().replace('_', '-');
}
